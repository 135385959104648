import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Pressable } from 'react-native';
import Button from '../Button';
import { MaterialIcons, FontAwesome, Ionicons } from '@expo/vector-icons';
import {
  Container,
  Content,
  ContentIcon,
  Wrapper,
  ViewPressable,
  ViewSuccess,
} from './style';
import {
  FooterText,
  SmallText,
  StandardText,
} from '../../config/theme/globalStyles';
import { v4 as uuidv4 } from 'uuid';

import { firestoreAutoId } from '../../utils';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ProgressBar, ActivityIndicator, Provider } from 'react-native-paper';
import { AlertBox } from '../../components/AlertBox';
import Constants from 'expo-constants';
import axios from 'axios';
import { firestore } from '../../services/firebase';
import { doc, updateDoc } from 'firebase/firestore';

const functions = getFunctions();

export function VideoUpload(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState();
  const [progressPanda, setProgressPanda] = useState();
  const [hoursLeft, setHoursLeft] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [error, setError] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const { onUploadCompleted, onClose, metadata, user, video } = props;
  const { projectId, pandaApiKey } = Constants.manifest.extra;

  const BASE_PANDA_API_URL = 'api-v2.pandavideo.com.br';
  const planType = user?.planType;

  let hoursAvailable = 0;

  if (user?.isAdmin && planType === 'premium') {
    hoursAvailable = 150;
  } else if (user?.isAdmin && planType === 'growth') {
    hoursAvailable = 100;
  }

  if (user?.isAdmin && user?.extraHours) {
    hoursAvailable += user.extraHours;
  }

  const getTotalLengthPanda = async () => {
    if (planType === 'ownPanda') {
      setHoursLeft('ilimitado');
      setProgressPanda(0);
      return;
    }

    try {
      setIsLoading(true);
      const getVideoHoursFromPanda = httpsCallable(
        functions,
        'getVideoHoursFromPanda'
      );

      let data = { user: user };
      const totalHoursResponse = await getVideoHoursFromPanda(data);
      const totalHoursUsed = totalHoursResponse.data.totalHours;

      if (totalHoursUsed !== null && totalHoursUsed !== undefined) {
        const hoursLeft = (hoursAvailable - totalHoursUsed).toFixed(1);
        const progressPanda =
          1 - (hoursAvailable - totalHoursUsed) / hoursAvailable;
        setHoursLeft(hoursLeft);
        setProgressPanda(progressPanda);
      }
    } catch (error) {
      console.log('Failed to get panda video hours', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOrCreateRootFolderByName = async (name) => {
    try {
      const api = axios.create({
        baseURL: `https://${BASE_PANDA_API_URL}`,
        headers: {
          authorization: pandaApiKey,
        },
      });

      const params = new URLSearchParams([['name', name]]);
      const getFoldersReponse = await api.get('/folders', { params });

      const folder = getFoldersReponse?.data?.folders.find(
        (folder) => folder.name === name
      );

      if (folder) return folder;

      const createReponse = await api.post('/folders', { name });
      return createReponse.data;
    } catch (error) {
      console.log('Erro', error);
    }
  };

  const getVideoLength = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.addEventListener('loadedmetadata', () => {
        URL.revokeObjectURL(video.src);
        const duration = video.duration / 3600;
        resolve(duration);
      });
      video.addEventListener('error', (error) => {
        URL.revokeObjectURL(video.src);
        reject(error);
      });
    });
  };

  useEffect(() => {
    getTotalLengthPanda();
  }, []);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setError({ title, message });
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const replaceVideoPanda = async () => {
    const apiUrl = 'https://api-v2.pandavideo.com.br/videos';

    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: pandaApiKey,
        },
        data: [{ video_id: video.videoInfo?.metadata?.id }],
      });

      if (response.status === 200) {
        return { success: true, message: 'Vídeo excluído com sucesso.' };
      } else {
        return { success: false, message: 'Falha ao excluir o vídeo.' };
      }
    } catch (error) {
      return { success: false, message: 'Erro ao excluir o vídeo.', error };
    }
  };

  const parseToBase64 = (string) => btoa(string);

  //Função antiga
 /*  const handleUpload = async (event) => {
    event.preventDefault();
    const file = event.target[0]?.files[0];

    const fileName = (name) => {
      return name.replace(/[^0-9a-zA-Z]+/g, '');
    };

    const getOrCreateFolder = await getOrCreateRootFolderByName(projectId);
    const folderId = getOrCreateFolder?.id;

    try {
      if (video.videoInfo?.metadata?.id) {
        await replaceVideoPanda();
      }

      setUploading(true);
      const response = await fetch(
        'https://api-v2.pandavideo.com.br/hosts/uploader',
        {
          method: 'GET',
          headers: { Authorization: pandaApiKey },
        }
      );

      const uploadServers = await response.json();
      const allHosts = Object.values(uploadServers.hosts).flat();
      const host = allHosts[Math.floor(Math.random() * allHosts.length)];
      const description = `databasePath:${metadata.databasePath},databasePropName:${metadata.databasePropName}`;

      if (!file) {
        showAlert('Erro', 'Não há arquivo para ser enviado.');
        throw new Error('No file attached');
      }
      const toLowerCaseType = file.type.toLowerCase();
      const allowedTypes = ['audio/', 'video/'];
      const isAllowed = allowedTypes.some((type) =>
        toLowerCaseType.includes(type)
      );

      if (!isAllowed) {
        setUploading(false);
        showAlert(
          'Formato de arquivo incorreto',
          'Selecione arquivos com formato de áudio ou vídeo.'
        );
        throw new Error('Incorrect format');
      }

      const duration = await getVideoLength(file);
      if (planType !== 'ownPanda' && hoursLeft < duration) {
        showAlert(
          'Aviso',
          'Você não possui horas suficientes de armazenamento para enviar este vídeo/áudio. Fale com o suporte para contratar mais horas.'
        );
        throw new Error('storageLimitReached');
      }

      const metadataVideoPanda = `authorization ${parseToBase64(
        pandaApiKey
      )}, filename ${parseToBase64(
        firestoreAutoId() + fileName(file.name)
      )}, video_id ${parseToBase64(uuidv4())}, folder_id ${parseToBase64(
        folderId
      )}, description ${parseToBase64(description)}`;

      const uploadResponse = await fetch(
        `https://${host}.pandavideo.com.br/files`,
        {
          method: 'POST',
          headers: {
            'Tus-Resumable': '1.0.0',
            'Upload-Length': file.size,
            'Upload-Metadata': metadataVideoPanda,
          },
        }
      );

      if (!uploadResponse.ok) {
        throw new Error('Failed to create upload URL');
      }

      const locationUrl = uploadResponse.headers.get('Location');
      const readableStream = file.stream().getReader();
      let bytesUploaded = 0;

      while (true) {
        const { done, value } = await readableStream.read();

        if (done) break;

        const chunk = value;
        const uploadContentResponse = await fetch(locationUrl, {
          method: 'PATCH',
          headers: {
            'Upload-Offset': bytesUploaded,
            'Tus-Resumable': '1.0.0',
            'Content-Type': 'application/offset+octet-stream',
          },
          body: chunk,
        });

        if (!uploadContentResponse.ok) {
          throw new Error('Failed to upload video content');
        }

        bytesUploaded += chunk.length;
        const progress = ((bytesUploaded / file.size) * 100).toFixed(2);
        setProgress(progress);
      }

      onUploadCompleted();
      setUploading(false);
      setUploadCompleted(true);

      const docRef = doc(firestore, metadata.databasePath);
      await updateDoc(docRef, {
        videoInfo: 'CONVERTING',
      });
    } catch (error) {
      setUploading(false);
      console.error(error);
      showAlert('Erro', 'Falha ao enviar o vídeo.');
    } finally {
      setUploading(false);
    }
  }; */

  const handleUpload = async (event) => {
  event.preventDefault();
  const file = event.target[0]?.files[0];

  const fileName = (name) => name.replace(/[^0-9a-zA-Z]+/g, '');

  try {
    if (!file) {
      showAlert('Erro', 'Não há arquivo para ser enviado.');
      throw new Error('No file attached');
    }

    const toLowerCaseType = file.type.toLowerCase();
    const allowedTypes = ['audio/', 'video/'];
    const isAllowed = allowedTypes.some((type) =>
      toLowerCaseType.includes(type)
    );

    if (!isAllowed) {
      showAlert(
        'Formato de arquivo incorreto',
        'Selecione arquivos com formato de áudio ou vídeo.'
      );
      throw new Error('Incorrect format');
    }

    const duration = await getVideoLength(file);
    if (planType !== 'ownPanda' && hoursLeft < duration) {
      showAlert(
        'Aviso',
        'Você não possui horas suficientes de armazenamento para enviar este vídeo/áudio. Fale com o suporte para contratar mais horas.'
      );
      throw new Error('Storage limit reached');
    }

    setUploading(true);

    const folder = await getOrCreateRootFolderByName(projectId);
    const folderId = folder?.id;

    if (video.videoInfo?.metadata?.id) {
      await replaceVideoPanda();
    }

    const response = await fetch(
      'https://api-v2.pandavideo.com.br/hosts/uploader',
      {
        method: 'GET',
        headers: { Authorization: pandaApiKey },
      }
    );

    const uploadServers = await response.json();
    const allHosts = Object.values(uploadServers.hosts).flat();
    const host = allHosts[Math.floor(Math.random() * allHosts.length)];

    const description = `databasePath:${metadata.databasePath},databasePropName:${metadata.databasePropName}`;
    const metadataVideoPanda = `authorization ${parseToBase64(
      pandaApiKey
    )}, filename ${parseToBase64(
      firestoreAutoId() + fileName(file.name)
    )}, video_id ${parseToBase64(uuidv4())}, folder_id ${parseToBase64(
      folderId
    )}, description ${parseToBase64(description)}`;

    const uploadResponse = await fetch(
      `https://${host}.pandavideo.com.br/files`,
      {
        method: 'POST',
        headers: {
          'Tus-Resumable': '1.0.0',
          'Upload-Length': file.size,
          'Upload-Metadata': metadataVideoPanda,
        },
      }
    );

    if (!uploadResponse.ok) {
      const errorText = await uploadResponse.text();
      throw new Error('Failed to create upload URL');
    }

    const locationUrl = uploadResponse.headers.get('Location');

    const uploadFileInChunks = async (
      file,
      chunkSize = 50 * 1024 * 1024,
    ) => {
      let offset = 0;

      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);

        for (let retries = 0; retries < 3; retries++) {
          try {
            const response = await fetch(locationUrl, {
              method: 'PATCH',
              headers: {
                'Tus-Resumable': '1.0.0',
                'Content-Type': 'application/offset+octet-stream',
                'Upload-Offset': offset,
              },
              body: chunk,
            });

            if (response.ok) {
              offset += chunk.size;
              const progress = ((offset / file.size) * 100).toFixed(2);
              setProgress(progress);
              break;
            } else if (response.status === 423) {
              await new Promise((res) => setTimeout(res, 1000));
            } else {
              const errorText = await response.text();
              throw new Error(
                `Failed to upload chunk with status ${response.status}`
              );
            }
          } catch (error) {
            if (retries === 2) {
              throw new Error('Failed to upload chunk after multiple attempts');
            }
          }
        }
      }
    };

    await uploadFileInChunks(file);

    const docRef = doc(firestore, metadata.databasePath);
    await updateDoc(docRef, {
      videoInfo: 'CONVERTING',
    });

    setUploadCompleted(true);
    onUploadCompleted();
  } catch (error) {
    showAlert('Erro', 'Falha ao enviar o vídeo.');
  } finally {
    setUploading(false);
  }
};





  return isLoading ? (
    <ActivityIndicator
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000a0',
      }}
      size="large"
      color="#FFFFFF"
    />
  ) : (
    <Provider>
      <Container>
        {uploadCompleted ? (
          <ViewSuccess>
            <MaterialIcons name="add-task" size={30} color="#3FC380E6" />
            <StandardText color="black" padding="1rem">
              Vídeo/Áudio adicionado com sucesso!
            </StandardText>
          </ViewSuccess>
        ) : (
          <>
            <ViewPressable>
              <Pressable onPress={onClose}>
                <ContentIcon>
                  <Ionicons name="close" size={26} color="black" />
                </ContentIcon>
              </Pressable>
            </ViewPressable>
            <Wrapper>
              <Content>
                <ContentIcon>
                  <FontAwesome
                    name="exclamation-triangle"
                    size={24}
                    color="red"
                  />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Atenção!
                  </StandardText>
                </ContentIcon>
                <SmallText>
                  Ao dar o upload de um novo vídeo ou áudio, o anterior (se
                  houver) será removido.
                </SmallText>
                <ContentIcon>
                  <MaterialIcons name="info" size={24} color="red" />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Informações importantes:
                  </StandardText>
                </ContentIcon>
                <SmallText textAlign="flex-start" padding="0.3rem 0rem">
                  Após enviar o vídeo ou áudio, ele será compactado
                  atomaticamente para reduzir o tamanho e melhorar a velocidade
                  de reprodução sem afetar a qualidade. Esse processo poderá
                  demorar até 3 horas, a depender do tamanho, para estar
                  disponível para seu aluno.
                </SmallText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color="black"
                >
                  Os únicos formatos aceitos são .mp4 e .MKV.
                </FooterText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color="black"
                >
                  Caso você precise adicionar um áudio, coloque-o em formato de
                  vídeo com uma imagem de fundo.
                </FooterText>
              </Content>
              {hoursLeft !== 'ilimitado' && (
                <View>
                  <ProgressBar
                    progress={progressPanda}
                    color="#3FC380E6"
                    style={{ height: '1.2rem', borderRadius: 30 }}
                  />
                  {hoursLeft < 0 ? (
                    <SmallText textAlign="center" padding="0.5rem 0rem">
                      Você atingiu o limite de {hoursAvailable} horas
                      disponíveis. Total de horas armazenadas:{' '}
                      {Math.abs(hoursLeft) + hoursAvailable}
                    </SmallText>
                  ) : (
                    <SmallText textAlign="center" padding="0.5rem 0rem">
                      Você possui {hoursLeft} hora(s) livre(s) de{' '}
                      {hoursAvailable} horas disponíveis.
                    </SmallText>
                  )}
                  <FooterText
                    textAlign="center"
                    padding="0.1rem 0rem"
                    color="black"
                  >
                    Caso necessite contratar mais horas, fale com o suporte.
                  </FooterText>
                </View>
              )}
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <form onSubmit={handleUpload}>
                  <input type="file" style={{ margin: '1rem' }}></input>
                  <button type="submit">Enviar</button>
                </form>
              </View>
              <AlertBox
                title={error?.title}
                message={error?.message}
                visible={visibleAlert}
                onClose={hideAlert}
                leftButtonFunction={hideAlert}
                leftButton={'OK'}
              ></AlertBox>
            </Wrapper>
          </>
        )}

        {uploadCompleted && (
          <View>
            <Button onPress={onClose} title="OK" />
          </View>
        )}
        {uploading && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: '#FFFFFF',
                alignItems: 'center',
                justifyContent: 'center',
              },
            ]}
          >
            {progress > 0 ? (
              <>
                <SmallText color="black" margin="1rem">
                  Carregando...
                </SmallText>
                <SmallText color="black" margin="1rem">
                  Permaneça nessa tela até o upload ser completado.
                </SmallText>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <progress value={progress} max="100" />
                  <FooterText color="black" margin="0rem 1rem">
                    {progress}%
                  </FooterText>
                </View>
              </>
            ) : (
              <SmallText color="black" margin="1rem">
                Carregando as informações do vídeo...
              </SmallText>
            )}
          </View>
        )}
      </Container>
    </Provider>
  );
}
