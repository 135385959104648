import React from 'react';
import { View, Image, Text, StyleSheet, Dimensions } from 'react-native';
import { useCustomImages } from '../../api/Brand';

const { width: screenWidth } = Dimensions.get('window');

const MockupSmartphone = ({ title, content }) => {
  const { data: logo } = useCustomImages('logoApp');

  console.log('custom info', logo);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('pt-BR', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/Sao_Paulo',
  });
  const formattedTime = currentDate.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'America/Sao_Paulo',
  });

  return (
    <View style={styles.container}>
      <Image
        source={require('../../../assets/mockupIOS.png')}
        style={styles.phoneMockup}
      />
      <View style={styles.dateTimeContainer}>
        <Text style={styles.date}>{formattedDate}</Text>
        <Text style={styles.time}>{formattedTime}</Text>
      </View>
      <View style={styles.notification}>
        <Image
          style={{ width: 40, height: 40 }}
          source={{
            uri: logo?.url,
          }}
        />
        <View style={styles.textContainer}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.message}>{content}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  phoneMockup: {
    width: 300,
    height: 600,
    borderRadius: 50,
    backgroundColor: 'gray',
  },
  notification: {
    position: 'absolute',
    top: 110,
    width: screenWidth * 0.13, 
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    flexDirection: 'row',
    minHeight: 80,
    alignItems: 'center',
  },
  textContainer: {
    paddingLeft: 6,
    flex: 1, 
  },
  title: {
    fontWeight: 'bold',
    fontSize: 12,
    flexShrink: 1, 
    flexWrap: 'wrap',
  },
  message: {
    fontSize: 12,
    flexShrink: 1, 
    flexWrap: 'wrap',
  },
  dateTimeContainer: {
    position: 'absolute',
    top: 35,
    alignItems: 'center',
    width: '100%',
  },
  time: {
    fontSize: 40,
    fontWeight: 'bold',
    color: '#fff',
  },
  date: {
    fontSize: 16,
    color: '#fff',
    marginTop: 6,
  },
});

export default MockupSmartphone;
